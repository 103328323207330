import React from "react";
import EndlessMarquee from "./EndlessMarquee";
import data from "../../data.json";

const getIcon = i => {
    if (i === 0) return <svg stroke="currentColor" color="rgb(237, 98, 98)" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="15px" width="15px" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="6"></circle><circle cx="12" cy="12" r="2"></circle></svg>
    if (i === 1) return <svg fill="rgb(118, 208, 235)" stroke-width="0" viewBox="0 0 640 512" height="15px" width="15px" xmlns="http://www.w3.org/2000/svg"><path d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"></path></svg>
    if (i === 2) return <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md" style={{ color: "rgb(226, 197, 65)" }}><path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C8.41496 3 5.5 5.92254 5.5 9.53846C5.5 11.8211 6.662 13.8298 8.42476 15H15.5752C17.338 13.8298 18.5 11.8211 18.5 9.53846C18.5 5.92254 15.585 3 12 3ZM14.8653 17H9.13473V18H14.8653V17ZM13.7324 20H10.2676C10.6134 20.5978 11.2597 21 12 21C12.7403 21 13.3866 20.5978 13.7324 20ZM8.12601 20C8.57004 21.7252 10.1361 23 12 23C13.8639 23 15.43 21.7252 15.874 20C16.4223 19.9953 16.8653 19.5494 16.8653 19V16.5407C19.0622 14.9976 20.5 12.4362 20.5 9.53846C20.5 4.82763 16.6992 1 12 1C7.30076 1 3.5 4.82763 3.5 9.53846C3.5 12.4362 4.93784 14.9976 7.13473 16.5407V19C7.13473 19.5494 7.57774 19.9953 8.12601 20Z" fill="currentColor"></path></svg>
    return <></>
}

export default function NoMsgs({ influencerID, setInp, inpRef, influencerData }) {
    const simpleQuery = influencerData?.simpleQuery || false;
    var suggestedQueries0 = influencerData?.suggestedQueries || [];
    if (!simpleQuery) {
        var suggestedQueries1 = influencerData?.suggestedQueries2 || [];
        var fullQueries0 = influencerData?.fullQueries || [];
        var fullQueries1 = influencerData?.fullQueries2 || [];
    }
    // For setting the input when user clicks, you can STILL wrap them in an onClick
    // by passing a callback or layering a div. 
    // But let's keep it simple at first:
    function onQueryClick(q) {
        setInp(q);
        inpRef.current.focus();
    }
    console.log(influencerData)
    return (
        <div className="emptyContainer">
            <img src="logo6.png" className="logoImage pulsatingGlow" style={{ opacity: ".6", width: "250px", marginBottom: "2rem", marginTop: "2rem" }} />
            {/* The marquee of queries */}
            {simpleQuery ?
                <div className='suggestedQueries'>
                    {suggestedQueries0.map((q, i) =>
                        <div className='query' onClick={() => {
                            setInp(q)
                            inpRef.current.focus()
                        }}>
                            <div>
                                {getIcon(i)}
                            </div>
                            {q}
                        </div>
                    )}
                </div> :
                <><EndlessMarquee
                    suggestedQueries={suggestedQueries0}
                    fullQueries={fullQueries0}
                    speed={40000} // pass whatever speed you want (in ms)
                    setInp={setInp} inpRef={inpRef}
                />
                    <EndlessMarquee
                        suggestedQueries={suggestedQueries1}
                        fullQueries={fullQueries1}
                        speed={40000} // pass whatever speed you want (in ms)
                        setInp={setInp} inpRef={inpRef}
                        reverse={true}
                    />
                </>
            }
        </div>
    );
}
