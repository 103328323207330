import React, { useState } from 'react'

import data from '../../data.json'
const { pics, names, bios } = data

export default function MiddleTop(
    { setShown, setClosed, influencerID, isCollapsed, setIsCollapsed, influencerData, isCalling, setIsCalling }
) {
    return (
        <div className="top">
            <div className='mini'>
                <button className="back icon back2"
                    onClick={() => setShown(0)}>
                    {/* <img src='back.svg' className='dots' height='16' /> */}
                    <svg stroke="#a0a0a0" fill="#a0a0a0" strokeWidth="0" viewBox="0 0 448 512" height="26px" width="26px" xmlns="http://www.w3.org/2000/svg">
                        <path d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"></path>
                    </svg>
                </button>

                <div className="profile">
                    {
                        isCollapsed && <div className="icon collapse-icon"
                            onClick={() => setIsCollapsed(!isCollapsed)}
                            style={{
                                // position: "absolute",
                                top: '.5em',
                                right: ".5em",
                                transform: `rotate(${isCollapsed ? 180 : 0}deg)`,
                                transition: 'transform 0.3s ease'
                            }}>
                            <svg stroke="#a0a0a0" fill="#a0a0a0" strokeWidth="0" viewBox="0 0 448 512" height="26px" width="26px" xmlns="http://www.w3.org/2000/svg">
                                <path d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"></path>
                            </svg>
                        </div>
                    }
                    <img
                        src={influencerData?.pic}
                        alt=""
                        style={{
                            aspectRatio: '1',
                            objectFit: 'cover',
                            borderRadius: '50%'
                        }}
                    />
                    <div className="desc">
                        <div className="name">{influencerData?.name}</div>
                        <div className="bio">{influencerData?.bio}</div>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", gap: "1em" }}>
                <button style={{ border: "none" }} className="icon" id='call' onClick={() => setIsCalling(true)}>
                    {/* <svg className='dots' stroke="#adadad" fill="#adadad" stroke-width="0" viewBox="0 0 512 512" height="22px" width="22px" xmlns="http://www.w3.org/2000/svg"><path d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path></svg> */}
                    <svg stroke="#adadad" fill="#adadad" stroke-width="0" viewBox="0 0 512 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z"></path></svg>
                </button>
                <button style={{ border: "none" }} className="icon" id="dots" onClick={() => {
                    setClosed(e => !e)
                    setShown(2)
                }}>
                    <img src='dots.svg' className='dots' height='22' />
                </button>
            </div>
        </div>
    )
}
