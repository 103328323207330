import React, { useContext, useEffect, useRef, useState } from 'react'
import data from '../../data.json'
import Modal from '../global/Modal';
import { AuthContext } from '../../AuthContextProvider';
import SubscriptionCTAModal from './SubscriptionCTAModal.tsx';

const { messageLimit, premiumMessageLimit } = data

function isTelegramWebApp() {
    // @ts-ignore
    return typeof TelegramWebviewProxy !== 'undefined';
}

export default function Input(
    { setInp, inpRef, handleSubmit, influencerID,
        inp, disabled, setShowBrowserModal,
        imageFile, setImageFile, processFile, showImg, paid }
) {
    // if (!user) return null;

    useEffect(() => {
        // Get the current URL parameters
        const params = new URLSearchParams(window.location.search);

        // Check if 'x' exists and is set to 'true'
        if (params.get('login') === 'true') {
            // googleRef.current?.click()

            setTimeout(() => {
                params.delete('login');
                const newUrl = `${window.location.pathname}?${params.toString()}`;
                window.history.replaceState({}, '', newUrl);

                setModalOpen(true)
            }, 1000)
        }
    }, []); // Empty dependency array to run only once on component mount

    const [isModalOpen, setModalOpen] = React.useState(false);
    const [rows, setRows] = useState(1)
    const sendRef = useRef()
    const formRef = useRef()
    const user = useContext(AuthContext)
    const [showUpgradeModal, setShowUpgradeModal] = useState(false)
    const fileInputRef = useRef();
    const leftRef = useRef()
    const [placeholderText, setPlaceholderText] = useState('Type your message here...');
    const [showSendText, setShowSendText] = useState(true);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            if (user || !paid) {

                if (paid && user.subscriptionStatus === "Basic" && user.tokenCount <= 0) {
                    setShowUpgradeModal(true)
                }
                else handleSubmit()
            }
            else setModalOpen(true)
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        if (imageFile === null && fileInputRef && fileInputRef.current) fileInputRef.current.value = null;
    }, [imageFile])

    useEffect(() => {
        const textarea = inpRef.current;
        sendRef.current.style.height = `${0}px`;
        textarea.style.height = 'auto';
        if (leftRef.current) leftRef.current.style.height = `${0}px`;

        const defaultHeight = window.innerWidth > 477 ? 65 : 50;
        var newHeight = defaultHeight

        if (textarea.scrollHeight <= defaultHeight) {
            newHeight = defaultHeight
            textarea.style.borderRadius = !showImg && paid ? '100px' : '0px'
            textarea.style.borderTopRightRadius = '0'
            textarea.style.borderBottomRightRadius = '0'
            sendRef.current.style.borderRadius = '100px'
            sendRef.current.style.borderTopLeftRadius = '0'
            sendRef.current.style.borderBottomLeftRadius = '0'
            if (leftRef && leftRef.current) {
                leftRef.current.style.borderRadius = '100px'
                leftRef.current.style.borderTopRightRadius = '0'
                leftRef.current.style.borderBottomRightRadius = '0'
            }
        }
        else {
            newHeight = textarea.scrollHeight
            textarea.style.borderRadius = !showImg && paid ? '3em' : '0px'
            textarea.style.borderTopRightRadius = '0'
            textarea.style.borderBottomRightRadius = '0'
            sendRef.current.style.borderRadius = '3em'
            sendRef.current.style.borderTopLeftRadius = '0'
            sendRef.current.style.borderBottomLeftRadius = '0'
            if (leftRef && leftRef.current) {
                leftRef.current.style.borderRadius = '3em'
                leftRef.current.style.borderTopRightRadius = '0'
                leftRef.current.style.borderBottomRightRadius = '0'
            }
        }

        const h = Math.min(newHeight, window.innerWidth > 477 ? 330 : 200)

        textarea.style.height = `${h}px`;
        // setSendHeight(newHeight)
        // sendRef.current.style.height = `${newHeight}px`;
        if (sendRef.current) {
            sendRef.current.style.height = `${h}px`;
        }
        if (leftRef.current) {
            leftRef.current.style.height = `${h}px`;
        }
    }, [inp, showImg]);

    useEffect(() => {
        const updateUI = () => {
            if (window.innerWidth < 400) {
                setPlaceholderText('Type message...');
                setShowSendText(false);
            } else {
                setPlaceholderText('Type your message here...');
                setShowSendText(true);
            }
        };

        updateUI(); // Initial check
        window.addEventListener('resize', updateUI);

        return () => window.removeEventListener('resize', updateUI);
    }, []);

    return (
        <>
            <form className="bott" ref={formRef} style={{
                zIndex: 999,
                position: "relative"
            }} onSubmit={e => {
                e.preventDefault()

                // Check for common in-app browsers
                const userAgent = navigator.userAgent || navigator.vendor || window.opera;

                const inAppBrowserRegex = /(Instagram|FBAN|FBAV|Twitter|Telegram)/i;

                if (influencerID === "aiguy" && (inAppBrowserRegex.test(userAgent) || isTelegramWebApp())) {
                    // Get the current URL
                    let url = new URL(window.location.href);

                    // Get search parameters
                    let params = new URLSearchParams(url.search);

                    // Add or update the parameter 'x=true'
                    params.set('login', 'true');

                    // Update the URL without reloading the page
                    window.history.pushState({}, '', `${url.pathname}?${params.toString()}`);
                    setShowBrowserModal(true);
                    return;
                }

                if (user || !paid) {
                    if (paid && user.subscriptionStatus === "Basic" && user.tokenCount <= 0) {
                        setShowUpgradeModal(true)
                    }
                    else handleSubmit()
                }
                else setModalOpen(true)
            }}>
                {
                    ((!paid || showImg) && imageFile) && <>
                        <div className='imgWrapper'>
                            <img src={URL.createObjectURL(imageFile)} className='imgPreview' />
                            <svg className="closeImg" stroke-width="0" viewBox="0 0 384 512" height="12px" width="12px"
                                onClick={() => {
                                    setImageFile(null)
                                    if (fileInputRef.current) {
                                        fileInputRef.current.value = null;
                                    }
                                }}
                                mlns="http://www.w3.org/2000/svg"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path></svg>
                        </div>
                    </>
                }
                {(!paid || showImg) &&
                    <>
                        <div className="sendContainer img" ref={leftRef} style={{
                            borderRadius: 0,
                            borderTopLeftRadius: "100px",
                            borderBottomLeftRadius: "100px",
                            paddingLeft: '1.2rem',
                            paddingRight: '.8rem',
                            position: "relative"
                        }} onClick={handleButtonClick}>
                            {/* <span style={{
                                position: "absolute",
                                background: "#ACBED8",
                                color: "#086788",
                                zIndex: 9999,
                                left: 0,
                                top: 0,
                                fontFamily: "Open Sans",
                                fontWeight: 700,
                                paddingInline: ".5em",
                                paddingBlock: ".1em",
                                fontSize: "10px",
                                borderRadius: "1000vw",
                                top: '1em',
                                left: '3em'

                            }}>Beta</span> */}
                            <svg stroke-width="0" viewBox="0 0 512 512" height="23px" width="23px" xmlns="http://www.w3.org/2000/svg"><path d="M0 96C0 60.7 28.7 32 64 32l384 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6l96 0 32 0 208 0c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"></path></svg>
                        </div>
                        <input
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={e => processFile(e.target.files[0])}
                        />
                    </>}
                <textarea style={{ resize: "none", borderRadius: 0, paddingLeft: (!paid || showImg) ? ".5em" : "1.5em" }} onKeyDown={handleKeyDown} rows={rows} ref={inpRef} type="text" className='input'
                    value={inp} maxLength={
                        user?.subscriptionStatus === "Premium" ?
                            premiumMessageLimit : messageLimit
                    } onChange={e => setInp(e.target.value)}

                    // disabled={!user || !user.uid}

                    placeholder={placeholderText} />
                <div className="sendContainer" ref={sendRef}>

                    <button className='send' disabled={disabled}>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="17" xmlns="http://www.w3.org/2000/svg"><path d="m476.59 227.05-.16-.07L49.35 49.84A23.56 23.56 0 0 0 27.14 52 24.65 24.65 0 0 0 16 72.59v113.29a24 24 0 0 0 19.52 23.57l232.93 43.07a4 4 0 0 1 0 7.86L35.53 303.45A24 24 0 0 0 16 327v113.31A23.57 23.57 0 0 0 26.59 460a23.94 23.94 0 0 0 13.22 4 24.55 24.55 0 0 0 9.52-1.93L476.4 285.94l.19-.09a32 32 0 0 0 0-58.8z"></path></svg>
                        {showSendText && 'Send'}
                    </button>
                </div>
            </form>
            <Modal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
            />
            {showUpgradeModal && (
                <SubscriptionCTAModal
                    remainingMessages={0}
                    onUpgrade={() => alert("HI")}
                    onClose={() => setShowUpgradeModal(false)}
                />
            )}
        </>
    )
}
