import React, { useEffect, useState } from 'react'
import Links from './Links';
import data from '../../data.json'

const { names, pics2, bios } = data

export default function RightBar({
    closed, influencerID, setClosed, shown, setShown,
    testMode, setTestMode, influencerData
}) {
    const [readMore, setReadMore] = useState(false)
    useEffect(() => setReadMore(false), [influencerID])

    return (
        <div className={`rightBar ${closed && "closed"} ${shown === 2 ? "shown" : "notShown"}`}>
            <div className="card" style={{ height: "100%", position: "relative" }}>
                <div className="text meet" style={{
                    position: "absolute",
                    top: "1em",
                    left: "1em",
                }}>
                    <button className="back icon"
                        onClick={() => {
                            setClosed(c => !c)
                            setShown(1)
                        }}>
                        <img src='back.svg' className='dots' height='16' />
                    </button>
                    {/* Meet {influencerData?.name}
                    {(window.location.href.startsWith("http://localhost") || window.location.href.includes("192.168.")) &&
                        <button style={{ backgroundColor: testMode ? "green" : "red" }} onClick={() => setTestMode(t => !t)}>
                            Test Mode
                        </button>
                    } */}
                </div>

                <div className="picContainer">
                    <img src={influencerData?.pic2} alt="" height="408" width="320" />
                    <div className="o"></div>
                    <div className="nameTitle">{influencerData?.name}</div>
                    <div className="nameDesc">{influencerData?.bio}</div>
                </div>

                <div className='desc desc2' style={{ marginTop: "2em", display: "block" }}>
                    {influencerData?.readMore}
                    {"  "}

                    {!readMore ? <>
                        {" "} <a className='link' onClick={() => setReadMore(r => !r)}>Read More...</a>
                    </> : <>
                        {influencerData?.readMore2}
                        {" "}<a className='link' onClick={() => setReadMore(r => !r)}>Show less...</a>
                    </>}
                </div>

                <div className="bottom">
                    <div className='text' style={{ fontSize: "16px" }}>Find {influencerData?.name}</div>
                    <Links influencerData={influencerData} />
                </div>
            </div>
        </div>
    )
}
