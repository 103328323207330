// Mapping of keywords/phrases to URLs
const linkMap = {

    'chatgpt': "https://chat.openai.com",
    'chatgpt.com': "https://chat.openai.com",
    'chat.openai.com': "https://chat.openai.com",

    'app.leonardo.ai': 'https://bit.ly/aiguyleonardo',
    'leonardo': 'https://bit.ly/aiguyleonardo',
    'leonardoai': 'https://bit.ly/aiguyleonardo',
    'leonardo ai': 'https://bit.ly/aiguyleonardo',
    'leonardo.ai': 'https://bit.ly/aiguyleonardo',

    'tubemagic': "https://bit.ly/aiguytube",
    'tube magic': "https://bit.ly/aiguytube",
    'tubemagic.com': "https://bit.ly/aiguytube",

    'dupdub': "https://bit.ly/aiguydupdub",
    'dupdub.com': "https://bit.ly/aiguydupdub",
    'dup dub': "https://bit.ly/aiguydupdub",
    'dubdub': "https://bit.ly/aiguydupdub",
    'dubdub.com': "https://bit.ly/aiguydupdub",
    'dub dub': "https://bit.ly/aiguydupdub",

    'vidiq': "https://bit.ly/aiguyvidiq",
    'vidiq.com': "https://bit.ly/aiguyvidiq",
    'vid iq': "https://bit.ly/aiguyvidiq",

    'epidemic sound': "https://bit.ly/aiguysound",
    'epidemicsound': "https://bit.ly/aiguyvidiq",
    'epidemicsound.com': "https://bit.ly/aiguyvidiq",

    'eleven lab': "https://bit.ly/aiguy11labs",
    'elevenlab': "https://bit.ly/aiguy11labs",
    'elevenlabs': "https://bit.ly/aiguy11labs",
    'eleven labs': "https://bit.ly/aiguy11labs",
    'elevenlabs.io': "https://bit.ly/aiguy11labs",
    '11labs': "https://bit.ly/aiguy11labs",
    '11 labs': "https://bit.ly/aiguy11labs",
    '11labs.io': "https://bit.ly/aiguy11labs",

    'vsub.io': "https://bit.ly/aiguyvsub",
    'vsub': "https://bit.ly/aiguyvsub",
    'v sub': "https://bit.ly/aiguyvsub",
    'vsubio': "https://bit.ly/aiguyvsub",
    'vsub io': "https://bit.ly/aiguyvsub",

    'heygen': "https://bit.ly/aiguyheygen",
    'hey gen': "https://bit.ly/aiguyheygen",
    'heygen.com': "https://bit.ly/aiguyheygen",

    'capcut': "https://bit.ly/aiguycapcut",
    'cap cut': "https://bit.ly/aiguycapcut",

    'vidyo': "https://bit.ly/aiguyvidyo",
    'vid yo': "https://bit.ly/aiguyvidyo",
    'vidyo ai': "https://bit.ly/aiguyvidyo",
    'vidyo.ai': "https://bit.ly/aiguyvidyo",

    'pikzels': 'https://bit.ly/aiguythumbnails',
    'pikzel': 'https://bit.ly/aiguythumbnails',
    'pik zels': 'https://bit.ly/aiguythumbnails',
    'pik zel': 'https://bit.ly/aiguythumbnails',
    'pikzel.com': 'https://bit.ly/aiguythumbnails',
    'pikzels.com': 'https://bit.ly/aiguythumbnails',

    'canva': "https://canva.com",
    'canva.com': "https://canva.com",

    'snappa': "https://canva.com",
    'canva': "https://canva.com",

    'Parrot Station': "https://parrotstation.com/",
    'https://parrotstation.com/': "https://parrotstation.com/",
    'ParrotStation': "https://parrotstation.com"

};

export default function parseString(str) {
    str = str.replace(/\[redacted\]/g, "TubeMagic")
    // Regular expression to match bold text and one or more newlines
    const regex = /(\*\*[^*]+\*\*|\n+)/g;
    const tokens = str.split(regex);

    // Function to escape special regex characters in phrases
    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    function processTextToken(token, index) {
        // Sort phrases by length in descending order to match longer phrases first
        const phrases = Object.keys(linkMap).sort((a, b) => b.length - a.length);

        // Prepare regex patterns for each phrase
        const phraseRegexes = phrases.map(phrase => {
            const escapedPhrase = escapeRegExp(phrase);
            const regex = new RegExp('^\\b' + escapedPhrase + '\\b', 'i');
            return { phrase, regex };
        });

        const elements = [];
        let remainingText = token;
        let idx = 0; // Index for React keys

        while (remainingText.length > 0) {
            let matched = false;
            for (let { phrase, regex } of phraseRegexes) {
                const match = regex.exec(remainingText);
                if (match) {
                    const matchedPhrase = match[0];
                    elements.push(
                        <a
                            href={linkMap[phrase]}
                            key={`${index}-${idx}`}
                            className="aff-link"
                            target="_blank"
                        >
                            {matchedPhrase}
                        </a>
                    );
                    remainingText = remainingText.slice(matchedPhrase.length);
                    matched = true;
                    idx++;
                    break;
                }
            }
            if (!matched) {
                // Add the first character if no phrase matched
                elements.push(remainingText[0]);
                remainingText = remainingText.slice(1);
                idx++;
            }
        }

        return elements;
    }

    const elements = [];
    tokens.forEach((token, index) => {
        if (!token) return; // Skip empty tokens

        if (token.startsWith('**') && token.endsWith('**')) {
            // Bold text: remove the ** markers and wrap in <strong>
            const content = token.slice(2, -2);

            // Process the content to replace phrases with links
            const processedTokens = processTextToken(content, index);
            elements.push(
                <strong key={index}>{processedTokens}</strong>
            );
        } else if (token.includes('\n')) {
            // Newlines: render a <br /> for each newline character
            const newlines = token.split('');
            newlines.forEach((_, idx) => {
                elements.push(<br key={`${index}-${idx}`} />);
            });
        } else {
            // Normal text
            const processedTokens = processTextToken(token, index);
            elements.push(...processedTokens);
        }
    });

    return <>{elements}</>;
}