import React, { useEffect, useState } from 'react';
import Call from '../Call/Call';

function AutoCall({ children }) {
    const [isCalling, setIsCalling] = useState(true);
    const [influencerData, setInfluencerData] = useState(null);

    useEffect(() => {
        // Listen for the custom event with influencer data
        const handleInfluencerData = (event) => {
            setInfluencerData(event.detail);
        };

        window.addEventListener('influencerDataLoaded', handleInfluencerData);

        return () => {
            window.removeEventListener('influencerDataLoaded', handleInfluencerData);
        };
    }, []);

    if (isCalling && influencerData) {
        return <Call setIsCalling={setIsCalling} influencerData={influencerData} />;
    }

    return children;
}

export default AutoCall; 