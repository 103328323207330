import React, { useState, useEffect } from 'react';
import Chat from './Chat';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebaseConfig';
import { useParams } from 'react-router-dom';

export default function ChatWrapper() {
    const [influencerData, setInfluencerData] = useState(null);
    const [loading, setLoading] = useState(true);
    var { influencerID } = useParams();

    useEffect(() => {
        if (!influencerID) {
            window.location.href = 'https://roastyourbusiness.com';
            return;
        }

        const fetchInfluencerData = async () => {
            try {
                const docRef = doc(firestore, 'influencers', influencerID);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setInfluencerData(data);
                    // Expose the influencer data to the parent component through a custom event
                    window.dispatchEvent(new CustomEvent('influencerDataLoaded', { 
                        detail: data 
                    }));
                } else {
                    console.log('No matching document for that ID.');
                }
            } catch (error) {
                console.error('Error fetching influencer data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInfluencerData();
    }, [influencerID]);
    // console.log(influencerData)
    // if (loading) return <p>Loading...</p>;

    return <Chat influencerData={influencerData} influencerID={influencerID} />;
}
