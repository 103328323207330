import React, { useEffect } from 'react';

const VapiAssistant = () => {
  useEffect(() => {
    // Create the script element
    const script = document.createElement('script');
    script.src = "https://cdn.jsdelivr.net/gh/VapiAI/html-script-tag@latest/dist/assets/index.js";
    script.async = true;
    script.defer = true;

    // When the script loads, run the assistant
    script.onload = () => {
      const assistant = "1e65c9ab-8823-4412-9d0c-dc44f1de486f";
      const apiKey = "c511b347-7f29-4262-aa4d-4c0c44bebca1";
      
      const buttonConfig = {
        position: "bottom-right",
        offset: "0px",
        width: "120px",
        height: "120px",
        idle: {
          color: "#E94FE9", // Deep purple for idle state.
          type: "round",
          icon: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z'%3E%3C/path%3E%3Cpath d='M19 10v2a7 7 0 0 1-14 0v-2'%3E%3C/path%3E%3Cline x1='12' y1='19' x2='12' y2='22'%3E%3C/line%3E%3C/svg%3E`
        },
        loading: {
          color: "rgb(186,85,211)", // Lighter purple when connecting.
          type: "round",
          icon: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M21 12a9 9 0 1 1-6.219-8.56'%3E%3C/path%3E%3C/svg%3E`
        },
        active: {
          color: "rgb(147,112,219)", // Soft purple when active.
          type: "round",
          icon: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7 2 2 0 0 1 1.72 2v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91'%3E%3C/path%3E%3Cline x1='23' y1='1' x2='1' y2='23'%3E%3C/line%3E%3C/svg%3E`
        }
      };

      // Initialize the assistant SDK
      const vapiInstance = window.vapiSDK.run({
        apiKey: apiKey,
        assistant: assistant,
        config: buttonConfig
      });

      // Optional event listeners
      vapiInstance.on('speech-start', () => { console.log('Speech has started'); });
      vapiInstance.on('speech-end', () => { console.log('Speech has ended'); });
      vapiInstance.on('call-start', () => { console.log('Call has started'); });
      vapiInstance.on('call-end', () => { console.log('Call has stopped'); });
      vapiInstance.on('volume-level', (volume) => { console.log(`Assistant volume level: ${volume}`); });
      vapiInstance.on('message', (message) => { console.log(message); });
      vapiInstance.on('error', (e) => { console.error(e); });
    };

    // Append the script to the document body
    document.body.appendChild(script);

    // Cleanup: remove the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <style>
        {`
          /* Force the Vapi button to be a perfect circle and center it */
          .vapi-btn {
            position: fixed !important;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            width: 120px !important;
            height: 120px !important;
            border-radius: 50% !important;
            margin: 0 !important;
          }
        `}
      </style>
      {/* Your website content goes here */}
    </>
  );
};

export default VapiAssistant;