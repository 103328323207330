// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Chat from './components/Chat/Chat'
import { AuthContextProvider } from './AuthContextProvider';
import SubscriptionHandler from './components/Subscription/SubscriptionHandler';
import axios from 'axios';
import { getDeviceId } from './utils/deviceId';
import AffiliateHandler from './components/Affiliate/AffiliateHandler';
import PricingPage from './components/Pricing/PricingPage.tsx';
import { setAffiliateCode } from './utils/affiliateCode.js'
import CongratulationsPage from './components/Congratulations/CongratulationsPage.tsx'
import { GlobalContextProvider } from './GlobalContextProvider.js';
import CaseStudy from './components/types/CaseStudy/CaseStudy.jsx';
import AffData from './components/AffData/AffData.jsx';
import ChatWrapper from './components/Chat/ChatWrapper.jsx';
import AutoCall from './components/AutoCall/AutoCall.jsx';
import VapiAssistant from './components/test.jsx';

function App() {

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const affiliateID = urlParams.get('ref');

        if (affiliateID) {
            setAffiliateCode(affiliateID);
            // Send event to backend
            // logEvent(affiliateID);
            axios.post("https://api.alphatwin.ai/visit", {
                deviceID: getDeviceId(),
                ref: affiliateID
            })
                .then(res => console.log(res))
                .catch(e => console.error(e))
        }
    }, []);

    // Check if the domain starts with "call."
    const isCallDomain = window.location.hostname.startsWith('call.');

    // If it's a call domain, we'll wrap the ChatWrapper with our AutoCall component
    const WrappedChatWrapper = ({ influencerID }) => {
        return isCallDomain ? (
            <AutoCall>
                <ChatWrapper influencerID={influencerID} />
            </AutoCall>
        ) : (
            <ChatWrapper influencerID={influencerID} />
        );
    };

    return (
        <GlobalContextProvider>
            <AuthContextProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={<WrappedChatWrapper influencerID={'aiguy'} />} />
                        {/* <Route path="/subscription" element={<SubscriptionHandler />} /> */}
                        <Route path="/affiliate" element={<AffiliateHandler />} />
                        <Route path="/pricing" element={<PricingPage />} />
                        <Route path="/congratulations" element={<CongratulationsPage />} />
                        {/* <Route path="/aiguy-case-study" element={<CaseStudy />} /> */}
                        <Route path="/affiliate_data" element={<AffData />} />
                        <Route path="/:influencerID" element={<WrappedChatWrapper />} />
                        <Route path="/test" element={<VapiAssistant />} />
                    </Routes>
                </Router>
            </AuthContextProvider>
        </GlobalContextProvider>
    );
}

export default App;
