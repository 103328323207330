import React, { useState, useEffect } from 'react';
import Vapi from '@vapi-ai/web';
import styles from './styles.module.css';

const vapi = new Vapi("c511b347-7f29-4262-aa4d-4c0c44bebca1"); // Replace with your actual public token
const iHeight = 34;

function Call({ setIsCalling, influencerData }) {
  const [isMuted, setIsMuted] = useState(false);
  const [interactionState, setInteractionState] = useState('idle'); // default state
  const [elapsedTime, setElapsedTime] = useState(0);
  const [callStatus, setCallStatus] = useState("inactive");
  const [volume, setVolume] = useState(0); // Volume level state
  const [hasStartedCall, setHasStartedCall] = useState(false);
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);

  // Increment elapsed time only when call is active
  useEffect(() => {
    let timer;
    if (callStatus === "active") {
      timer = setInterval(() => {
        setElapsedTime(prevTime => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [callStatus]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  // Start the Vapi call when start button is clicked
  const startCall = async () => {
    setCallStatus("loading");
    setHasStartedCall(true);
    try {
      setIsLoadingVisible(true);
      await vapi.start(influencerData.vapiID || "1e65c9ab-8823-4412-9d0c-dc44f1de486f");
    } catch (error) {
      console.error("Failed to start call:", error);
      setCallStatus("inactive");
      setHasStartedCall(false);
    }
  };

  const endCall = async () => {
    setCallStatus("inactive");
    setHasStartedCall(false);
    setElapsedTime(0);
    await vapi.stop();
  };

  // Listen for call events, speech events, and volume updates
  useEffect(() => {
    vapi.on("call-start", () => {
      setCallStatus("active");
      // Start fade out
      const timer = setTimeout(() => {
        setIsLoadingVisible(false);
      }, 100); // Small delay to ensure smooth transition
      return () => clearTimeout(timer);
    });
    vapi.on("call-end", () => setCallStatus("inactive"));

    // Update interaction state when speech starts/ends
    vapi.on("speech-start", () => setInteractionState("aiTalking"));
    vapi.on("speech-end", () => setInteractionState("idle"));

    // Listen for volume-level events and update state
    vapi.on("volume-level", (vol) => {
      setVolume(vol);
    });

    return () => {
      vapi.removeAllListeners();
    };
  }, []);

  const toggleMute = () => {
    if (vapi.isMuted()) {
      vapi.setMuted(false);
      setIsMuted(false);
    } else {
      vapi.setMuted(true);
      setIsMuted(true);
    }
  };

  // Apply dynamic style only when speaking; idle state is handled via CSS
  const dynamicRingStyle = interactionState === "aiTalking" ? {
    transform: `translate(-50%, -50%) scale(${1 + volume * 0.5})`,
    boxShadow: `0 0 ${15 + volume * 20}px ${5 + volume * 5}px rgba(185,85,211,${0.6 + volume * 0.2})`,
    transition: 'transform 0.1s linear, box-shadow 0.1s linear'
  } : {};

  // The container's class will include either "idle" or "aiTalking"
  const avatarContainerClass = `${styles['avatar-container']} ${styles[interactionState]}`;

  return (
    <div className={styles['app-container']}>
      <div className={styles['gradient-bg']} />

      <div className={styles['main-content']}>
        <div className={avatarContainerClass}>
          <img
            src={influencerData.pic}
            alt="AI Assistant"
            className={styles['avatar-image']}
          />
          <div className={styles['avatar-ring']} style={dynamicRingStyle}></div>
          <div className={`${styles['avatar-ring2']} ${interactionState !== 'aiTalking' ? styles.show : ''}`} style={dynamicRingStyle}></div>
        </div>

        <h2 className={styles['ai-name']}>{influencerData.name}</h2>
        <p className={styles['ai-role']}>{influencerData.bio}</p>

        <div className={styles['status-container']}>
          {/* Optional status info */}
        </div>

        <div className={styles['controls-container']}>
          {!hasStartedCall ? (
            <button
              onClick={startCall}
              className={`${styles['control-button']} ${styles['start-button']}`}
            >
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height={iHeight} width={iHeight} xmlns="http://www.w3.org/2000/svg"><path d="M391 480c-19.52 0-46.94-7.06-88-30-49.93-28-88.55-53.85-138.21-103.38C116.91 298.77 93.61 267.79 61 208.45c-36.84-67-30.56-102.12-23.54-117.13C45.82 73.38 58.16 62.65 74.11 52a176.3 176.3 0 0 1 28.64-15.2c1-.43 1.93-.84 2.76-1.21 4.95-2.23 12.45-5.6 21.95-2 6.34 2.38 12 7.25 20.86 16 18.17 17.92 43 57.83 52.16 77.43 6.15 13.21 10.22 21.93 10.23 31.71 0 11.45-5.76 20.28-12.75 29.81-1.31 1.79-2.61 3.5-3.87 5.16-7.61 10-9.28 12.89-8.18 18.05 2.23 10.37 18.86 41.24 46.19 68.51s57.31 42.85 67.72 45.07c5.38 1.15 8.33-.59 18.65-8.47 1.48-1.13 3-2.3 4.59-3.47 10.66-7.93 19.08-13.54 30.26-13.54h.06c9.73 0 18.06 4.22 31.86 11.18 18 9.08 59.11 33.59 77.14 51.78 8.77 8.84 13.66 14.48 16.05 20.81 3.6 9.53.21 17-2 22-.37.83-.78 1.74-1.21 2.75a176.49 176.49 0 0 1-15.29 28.58c-10.63 15.9-21.4 28.21-39.38 36.58A67.42 67.42 0 0 1 391 480z"></path></svg>
              Start Call
            </button>
          ) : (
            <>
              <button
                onClick={toggleMute}
                className={`${styles['control-button']} ${styles['mute-button']} ${isMuted ? styles.muted : ''}`}
              >
                {isMuted ? (
                  // Muted icon
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iHeight}
                    height={iHeight}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="2" x2="22" y1="2" y2="22"></line>
                    <path d="M18.89 13.23A7.12 7.12 0 0 0 19 12v-2"></path>
                    <path d="M5 10v2a7 7 0 0 0 12 5"></path>
                    <path d="M15 9.34V5a3 3 0 0 0-5.68-1.33"></path>
                    <path d="M9 9v3a3 3 0 0 0 5.12 2.12"></path>
                    <line x1="12" x2="12" y1="19" y2="22"></line>
                  </svg>
                ) : (
                  // Regular mic icon
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iHeight}
                    height={iHeight}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z"></path>
                    <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                    <line x1="12" x2="12" y1="19" y2="22"></line>
                  </svg>
                )}
              </button>

              <button
                onClick={endCall}
                className={`${styles['control-button']} ${styles['hangup-button']}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={iHeight}
                  height={iHeight}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7 2 2 0 0 1 1.72 2v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91"></path>
                  <line x1="22" x2="2" y1="2" y2="22"></line>
                </svg>
              </button>
            </>
          )}
        </div>
      </div>
      {console.log(influencerData)}
      <div className={styles['logo-container']}>
        {influencerData.name !== "Jessica Os" && <a href='/' className="logo">
          <img src="logo7.png" alt="logo" height="40px" className="imgLogo" />
          <span className={styles['logo-text']}>AlphaTwin</span>
        </a>}
      </div>

      <div className={styles['premium-badge']}>
        <span>{formatTime(elapsedTime)}</span>
      </div>

      {(callStatus === "loading" || isLoadingVisible) && (
        <div className={`${styles['call-status']} ${callStatus !== "loading" ? styles['fade-out'] : ''}`}>
          <div className={styles['loading-spinner']} />
          <span>Connecting...</span>
        </div>
      )}
    </div>
  );
}

export default Call;
